<template>
  <div class="card card-psh flex-fill">
    <div class="bg-light-primary rounded-top py-2 px-1">
      <div class="psh-header mb-0 d-flex align-items-center">
        <div class="mr-2 border-0">
          <p-icon name="bi-credit-card-2-back-fill" size="52px" color="primary" />
        </div>
        <div class="my-auto d-flex flex-row w-100">
          <div class="d-flex flex-column flex-grow-1">
            <h4 class="card-title mb-25">
              Invoice and Transaction Details
            </h4>
            <p class="card-text mb-0">
              Invoice No: #{{ invoiceInfo.invoiceNo }}
            </p>
          </div>
          <div class="d-flex flex-column">
            <h4 class="card-title mb-25">
              {{ invoiceInfo.companyName }}
            </h4>
            <p class="card-text mb-0">
              {{ invoiceInfo.storeName }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body px-1">
      <div class="row mb-2">
        <div class="col-12">
          <div class="d-flex flex-column justify-content-start">
            <div class="d-flex flex-row row mb-half">
              <div class="display-h-4 text-warning font-weight-bold col-1">
                Total
              </div>
              <div class="display-h-4">
                : ${{ invoiceInfo.invoiceAmount.toFixed(2) }}
              </div>
            </div>
            <div class="d-flex flex-row row mb-half">
              <div class="display-h-4 text-warning font-weight-bold col-1">
                Credit
              </div>
              <div class="display-h-4">
                : ${{ invoiceInfo.balanceUsed.toFixed(2) }}
              </div>
            </div>
            <div class="d-flex flex-row row mb-half">
              <div class="display-h-4 text-warning font-weight-bold col-1">
                Paid
              </div>
              <div class="display-h-4">
                : ${{ invoiceInfo.totalPaid.toFixed(2) }}
              </div>
            </div>
            <div class="d-flex flex-row row mb-half">
              <div class="display-h-4 text-warning font-weight-bold col-1">
                Due
              </div>
              <div class="display-h-4">
                : ${{ invoiceInfo.totalDue.toFixed(2) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <dx-data-grid
        id="transactionGrid"
        ref="transactionGridRef"
        :data-source="TransactionDataSource"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :row-alternation-enabled="false"
        :hover-state-enabled="true"
        :customize-columns="customizeColumns"
        @option-changed="onOptionChanged"
        @initialized="onInitialized"
        @content-ready="onContentReady"
        @row-expanding="onRowExpanding"
      >
        <dx-column data-field="payment_method.person_name" caption="Person Name" />
        <dx-column data-field="card_type" caption="Card Type" alignment="center" cell-template="cardTypeCellTemplate" />
        <template #cardTypeCellTemplate="{ data }">
          <b-img height="32" :src="data.value" :title="data.value" />
        </template>
        <dx-column data-field="last_four" caption="Card Number" />
        <dx-column data-field="total" :format="{ type: 'currency', precision: 2 }" />
        <dx-column data-field="total_refunded" caption="Refunded" :format="{ type: 'currency', precision: 2 }" />
        <dx-column data-field="type" caption="Type" alignment="center" cell-template="successCellTemplate" />
        <template #successCellTemplate="{ data }">
          <span class="d-block badge" :class="getTransactionTypeFormated(data.data).variant">
            {{ getTransactionTypeFormated(data.data).text }}
          </span>
        </template>
        <dx-column data-field="is_voidable" caption="Voidable" cell-template="voidableCellTemplate" />
        <template #voidableCellTemplate="{ data }">
          <span class="badge" :class="{ 'badge-light-success': data.value, 'badge-light-danger': !data.value }">
            {{ data.value ? 'Yes' : 'No' }}
          </span>
        </template>
        <dx-column data-field="is_refundable" caption="Refundable" cell-template="refundableCellTemplate" />
        <template #refundableCellTemplate="{ data }">
          <span class="badge" :class="{ 'badge-light-success': data.value, 'badge-light-danger': !data.value }">
            {{ data.value ? 'Yes' : 'No' }}
          </span>
        </template>
        <dx-column data-field="created_at" caption="Created At" />
        <dx-column data-field="message" width="100%" />
        <dx-column v-if="hasPermission" type="buttons" caption="Actions" :width="190">
          <dx-button
            ref="btnAddToBatch1"
            :visible="isVoidButtonDisabled"
            :disabled="isVoidButtonDisabled"
            text="Void"
            css-class="btn btn-secondary disabled"
          />
          <dx-button
            ref="btnAddToBatch2"
            :visible="isVoidButtonNotDisabled"
            text="Void"
            css-class="btn btn-danger"
            :on-click="onClickVoid"
          />
          <dx-button
            ref="btnRefund"
            :visible="isRefundButtonDisabled"
            text="Refund"
            css-class="btn btn-secondary disabled"
            :disabled="isRefundButtonDisabled"
          />
          <dx-button
            ref="btnRefund"
            :visible="isRefundButtonNotDisabled"
            text="Refund"
            css-class="btn btn-warning"
            :on-click="onClickRefund"
          />
        </dx-column>
        <dx-master-detail :enabled="true" template="masterDetailTemplate" />
        <template #masterDetailTemplate="{ data: items }">
          <child-transaction :items="items.data.child_transactions" />
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
/* eslint-disable consistent-return */

import invoiceService from '@/http/requests/finance/invoiceService'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { TransactionDataSource } from './transaction.store'
import ChildTransaction from './ChildTransaction.vue'

export default {
  components: {
    ChildTransaction,
  },
  mixins: [GridBase],
  data() {
    return {
      id: null,
      TransactionDataSource,
      refundAmount: 0,
      invoiceInfo: {},
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_accounting_invoice_admin')
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      await this.getInvoiceInfo()
      this.loadData()
    }
  },
  methods: {
    getTransactionTypeFormated(transaction) {
      if (transaction.message && transaction.message.startsWith('Unable ')) return { variant: 'danger', text: 'X Failed Charge' }
      if (transaction.is_voided) return { variant: 'badge-light-danger', text: 'Voided' }
      if (transaction.total_refunded > 0 && transaction.total_refunded < transaction.total) return { variant: 'badge-light-warning', text: 'Partially Refunded' }
      if (transaction.total_refunded === transaction.total) return { variant: 'badge-light-warning', text: 'Refunded' }
      if (transaction.total !== 0) return { variant: 'badge-success', text: 'Charge' }
      return { variant: 'badge-light-secondary', text: 'Unknown' }
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        TransactionDataSource.pageIndex(0)
        TransactionDataSource.reload()
      }
    },
    onRowExpanding(e) {
      e.component.collapseAll(-1)
    },
    async getInvoiceInfo() {
      const result = await invoiceService.getInvoiceInfoById(this.id)
      this.invoiceInfo = {
        invoiceNo: `${result.suiteNo}-${(result.invoiceNo.toString()).padStart(5, '0')}`,
        companyName: result.companyName,
        balanceUsed: result.balanceUsed,
        invoiceAmount: result.invoiceAmount,
        storeName: result.storeName,
        totalPaid: result.totalPaid,
        totalDue: result.totalDue,
      }
    },
    loadData() {
      const filters = {
        id: this.id,
      }
      TransactionDataSource.searchValue(filters)
      TransactionDataSource.load()
    },
    isVoidButtonDisabled(e) {
      return !e.row.data.is_voidable
    },
    isVoidButtonNotDisabled(e) {
      return e.row.data.is_voidable
    },
    isRefundButtonDisabled(e) {
      return !e.row.data.is_refundable
    },
    isRefundButtonNotDisabled(e) {
      return e.row.data.is_refundable
    },
    onClickVoid(e) {
      const { data } = e.row
      this.$swal({
        title: 'Are you sure you want to void this payment?',
        text: "You won't be able to revert this action once confirmed!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Void',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await invoiceService.voidTransaction(this.id, data.id, data.total)
          await this.getInvoiceInfo()
          this.loadData()
        }
      })
    },
    onClickRefund(e) {
      // eslint-disable-next-line no-unused-vars
      const { data } = e.row
      this.$swal({
        title: 'Are you sure you want to make a refund on this payment?',
        text: "You won't be able to revert this action once confirmed!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Refund',
        buttonsStyling: false,
        input: 'text',
        inputPlaceholder: 'Enter the refund amount',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
          input: 'my-1',
        },
        inputValidator: value => {
          if (!value) {
            return 'Refund amount is required!'
          }
          const val = Number.parseFloat(value)
          if (Number.isNaN(val)) {
            return 'Enter a valid amount'
          }
          if (val > data.total) {
            return 'Refund amount cannot be greater than payment amount'
          }
        },
      }).then(async result => {
        if (result.value) {
          await invoiceService.refundTransaction(this.id, data.id, Number.parseFloat(result.value))
          await this.getInvoiceInfo()
          this.loadData()
        }
      })
    },
  },
}
</script>

<style>

</style>
