/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import staxService from '@/http/requests/external/stax'
import Pager from '@core/dev-extreme/utils/pager'
import moment from 'moment'

// eslint-disable-next-line import/no-dynamic-require
const getCardTypeLink = type => require(`@/assets/images/svg/cards/${type.toLowerCase()}.svg`)

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'id', desc: false }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortOrder(sort)

    const pageableQuery = `${pager.toCreatePageable()}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    const response = await staxService.getTransactionsByInvoiceId(filters.id, pageableQuery)
    return {
      data: response,
      totalCount: response.length,
    }
  },
})

const transactionDataSource = new DataSource({
  store: store,
  map: dataItem => {
    const now = moment().valueOf()
    const then = moment(moment(dataItem.created_at).format('YYYY-MM-DD HH:mm:ss')).add(10, 'day').valueOf()
    const duration = moment.duration(moment(then).diff(now))

    const item = { ...dataItem }
    if (item.method === 'bank') {
      item.is_voidable = false
    }
    if (item.method === 'bank' && duration.asDays() <= 0) {
      item.is_refundable = false
    }
    item.last_four = `****-****-****-${item.last_four}`
    const cardType = item.payment_method.card_type ? item.payment_method.card_type : 'ach'
    const cardLogoLink = getCardTypeLink(cardType)
    item.card_type = cardLogoLink
    return item
  },
})

export {
  transactionDataSource as TransactionDataSource,
}
